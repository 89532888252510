@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  @apply bg-background dark:bg-background-dark antialiased font-sans text-primary dark:text-primary-dark;
}

@layer base {
  html {
    @apply scroll-smooth;
  }
}